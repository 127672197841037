import React from "react";
import { projs } from "../Comps/proj.js";
import links from "../Comps/links.js";

const Footer = () => {
  return (
    <div id="footerholder">


      <div id="footContainer">
        <div id="footerLinks">
          <h4>MENU</h4>
          <div id="fooLine" ></div>
          <span className="monst" onClick={(e) => (links("home"))} >Home</span>
          <span className="monst" onClick={(e) => (links("me"))}>Journal</span>
          <span className="monst" onClick={(e) => projs("serverSphere")}>Projects</span>
        </div>
        <div id="footerLinks">
          <h4>Socials</h4>
          <div id="fooLine" ></div>
          <a className="monst" onClick={(e) => (links("git"))}>Github</a>
          <a className="monst" onClick={(e) => (links("linkedIn"))} >LinkedIn</a>
        </div>
        <div id="footerLinks">
          <h4>Lets Work</h4>
          <div id="fooLine" ></div>
          <a className="monst" onClick={(e) => {
            // Toggle side menu visibility
            const sideMenu = document.getElementById('side-contact');
            sideMenu.classList.toggle('visible');
          }} >Email Me</a>
        </div>

      </div>


      <div id="miniFoot">
      <div href="/" id="bot3">
        <div id="namelg" className='miniLogo' >
          <a href="/">
            <h3>
              EBM.
            </h3>
          </a>
        </div>
        <div id='version' className="monst">v2.1.0</div>
      </div>
      <div  id="copyrightHold">
      <small id="copyright"className="txts">All rights Reserved. Copyright 2025 </small>
      <small id="upDATE"className="monst">Updated: 01/01/25</small>
      </div>
      </div>
    </div>
  );



}

export default Footer;
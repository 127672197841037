import React, { Fragment, useEffect } from 'react';
import Nav from "../Comps/Nav.js";
import Ren from "../Comps/ani.js";
import cloneElementMultipleTimes from "../Comps/cloneElem.js";
import links from "../Comps/links.js";
import cursorPointer from "../Comps/cursorPointer.js";
import Gsaps from "../Comps/move.js";
import LsbBar from "../Comps/lsbBar.js";
import Lmb from "../Comps/menuBar.js";
import Footer from "../Comps/footer.js";
import Cards from "../Comps/cards.js";
import LoadingPage from "../Comps/preloader.js";
import { Canvas } from '@react-three/fiber'
import Marquee from "react-fast-marquee";
import gsap from 'gsap';
import Scribb from "../Comps/flower1.js";
import Flower2 from "../Comps/flower2.js";
import { projs } from "../Comps/proj.js";
import SideContact from '../Comps/contactMenu.js';
import Repos from '../Comps/repos.js';
function Home() {


  const user = JSON.parse(sessionStorage.getItem('user'));
  let isLoggedIn = false;

  if (user) {
    console.log(user);
    isLoggedIn = true;
  }


  cursorPointer();
  Gsaps();





  const nameElement = <div id="name" className="txts">Esau BM. </div>;
  const repeatedElements = cloneElementMultipleTimes(nameElement, 5); // Adjust the number of repetitions as needed

  return (
    <Fragment>
      <LoadingPage />
      <Lmb />
      <SideContact />

      <div className="Main-Cont">

        <div className="blob" id="not">
        </div>
        <div className='blobName txts' id="not">
          VIEW
        </div>
        <header className="site-header">
          <Nav />
        </header>

        <div className="site-content">

          {/* <div id="spinner">
              <Scribb/>
              <Flower2/>
          </div> */}

          <div className="fs lineUp-front">

            <div id="webDev">
              <h3 className='monst'>WEB DEVELOPER</h3>
            </div>

            <Marquee
              speed={150}
              direction="left"
              pauseOnClick
            >
              {repeatedElements}
            </Marquee>

            <div className='scaler' >
              <h6 className='monst' >
                Available JAN. 2025
              </h6>

              <div className='city'>
                <p className="monst">Los Angeles</p>
                <p className="monst">San Francisco</p>
              </div>
            </div>

          </div>

          <div id="miniNav">
            <div id="contNProj">
              <div id="bottomLnk" onClick={(e) => links("me")}>
                <h2 className='botContact'>
                  Journal
                </h2>
              </div>
              <div id="bottomLnk" onClick={(e) => projs("serverSphere")}>
                <h2 className='botproj'>
                  Projects
                </h2>
              </div>
            </div>
            <div className='pe'>
              <h2>

                <img src={require("../Images/github-mark.png")} className='socials interactable so' alt="loading..." onClick={(e) => (links("git"))} />
                <img src={require("../Images/Send_fill.png")} className=' email socials interactable' alt="loading..."
                  onClick={(e) => {
                    // Toggle side menu visibility
                    const sideMenu = document.getElementById('side-contact');
                    sideMenu.classList.toggle('visible');
                  }} />
                <img src={require("../Images/blin.png")} className='socials interactable so' alt="loading..." onClick={(e) => (links("linkedIn"))} />
              </h2>
            </div>

          </div>

          {/* <img src={require("../Images/gif.gif")} className='gigg' alt="loading..." /> */}
        </div>


        <aside className="site-projects">

          {/* ABOUT ME SECTION */}
          <div id="Fullgrid">
            <div id="about">
              <div id='fullStackCont'>
                <h2 id='fullStackConttxt'>
                  <span id="full">Full</span>
                  <span id="stack">Stack.</span>
                  {/* <span id="dev">Dev</span> */}
                </h2>

                {/* <h6 className='txtSub' id='serv'>(Me)</h6> */}
              </div>

              <div id="picNStats">
                <div id="WorkStats">
                  <h3 >Esau B. Medina</h3>
                  <div id="desLine" ></div>

                  <p className="monst">Los Angeles Based Developer.<br />
                    B.S. Computer Science<br />
                    San Francisco State Uni. </p>
                  {/* <div id="desLine" ></div>
                  <p>Full Stack Development</p> */}
                </div>
                <img src={require("../Images/BUP3.jpg")} id="chuch" className='ProPic' alt="loading..." />
              </div>
            </div>

            <div id="horiLine"></div>


            <div id="lineChoice">
              <h3 id=''>Chosen Pieces</h3>
            </div>

            {/* Project Section */}
            <Cards />
          </div>

          <div id="lineChoice">
            <h3 id=''>Repositories</h3>
          </div>
          <Repos/>

        </aside>

        <div className="site-footer">
          <Footer />
        </div>


      </div>
    </Fragment>
  );
}

export default Home;
